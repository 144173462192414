



















































































import {
  Component, Vue,
} from 'vue-property-decorator';
import { ref } from '@vue/composition-api';
import { classes } from '@battletris/shared/functions/classes';
import ViewWrapper from '../components/ViewWrapper.vue';

import { getRequest } from '../lib/request';
import AbilityLogo from '../icons/AbilityLogo.vue';
import Loading from '../components/Loading.vue';
import UserSetting from '../components/UserSetting.vue';
import user from '../lib/User';

import WarriorIcon from '../icons/warrior.vue';
import SorcererIcon from '../icons/sorcerer.vue';
import UnknownIcon from '../icons/unknown.vue';

@Component({
  components: {
    AbilityLogo,
    Loading,
    SorcererIcon,
    UnknownIcon,
    UserSetting,
    ViewWrapper,
    WarriorIcon,
  },
  setup() {
    const name = ref<string>(user.name);
    const className = ref(user.className);
    const userId = ref(user.id);
    console.log(classes);
    const classList = Object.keys(classes);
    const activeClassIndex = ref(classList.findIndex((key) => className.value === key));
    const matches = ref();
    const loading = ref(true);

    const init = async () => {
      loading.value = true;
      matches.value = await getRequest('user/matches');
      loading.value = false;
    };

    let debounce: ReturnType<typeof setTimeout>;
    const updateUser = () => {
      if (debounce) {
        window.clearTimeout(debounce);
      }

      if (name.value.length > 50) {
        name.value = name.value.slice(0, 50);
      }

      debounce = setTimeout(() => user.update(name.value, className.value), 500);
    };

    const selectClass = (increase: number) => {
      activeClassIndex.value += increase;

      if (activeClassIndex.value < 0) {
        activeClassIndex.value = classList.length - 1;
      } else if (activeClassIndex.value >= classList.length) {
        activeClassIndex.value = 0;
      }

      className.value = classList[activeClassIndex.value];
      updateUser();
    };

    // session import / export
    const workingOnBattletrisId = ref(false);
    const importError = ref(false);
    const uploadIdRef = ref<{ files: Blob[] } | null>(null);
    const importUser = async () => {
      loading.value = true;
      try {
        await user.import(uploadIdRef.value as { files: Blob[] });
        await init();
        importError.value = false;
      } catch (ex) {
        importError.value = true;
      }
      // use latest user data
      name.value = user.name;
      className.value = user.className;
      loading.value = false;
    };

    init();

    return {
      activeClassIndex,
      classList,
      className,
      importError,
      importUser,
      loading,
      matches,
      name,
      selectClass,
      updateUser,
      uploadIdRef,
      user,
      userId,
      workingOnBattletrisId,
    };
  },
})
export default class Settings extends Vue {}
